@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:root
{
  --baseColor : #fff;
  --babyBlue-50 : #EFF3FB;
  --babyBlue-75 : #EBEFFE;
  --babyBlue-100 : #D3DFF7;

  --darkPrimary : #05389b;
  --primaryColor : #074acc;
  --secondaryColor : #FF9E01;
  --thirdColor : #0FAC83;
  --fourthColor : #CC3F0C;
  --fifthColor : #18BECB;

  --lightGray: #999999;
  --mediumGray : #808080;
  --darkGray : #333333;

  --primaryTile : #0d5bf8bf;
  --secondaryTile : rgba(255, 157, 0, 0.75);
  --thirdTile : rgba(15, 174, 131, 0.75);
  --fourthTile : rgba(204, 64, 13, 0.75);
  --fifthTile : rgba(24, 191, 203, 0.75);    
}
html {
  scroll-behavior: smooth;
}
body
{
  font-family: 'Cairo', sans-serif;
  overflow-x: hidden;
}
.main
{
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
}

/* Scroll bar*/
.scrollbar-style::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2); */
	border-radius: 10px;
	background-color: transparent;
}

.scrollbar-style::-webkit-scrollbar
{
	width: 8px;
	background-color: transparent;
}

.scrollbar-style::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
	background-color: var(--babyBlue-100);
}

.containerBox .scrollbar-popup-style::-webkit-scrollbar
{
  background-color: transparent;
  border-radius: 10px;
}