@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');
@import "./variables.scss";

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html {
    scroll-behavior: smooth;
}
body
{
    font-family: 'Cairo', sans-serif;
    overflow-x: hidden;
}
.main
{
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
}
.custom-toast
{
    font-family: 'Cairo', sans-serif !important;
}
/* Scroll bar*/
.scrollbar-style::-webkit-scrollbar-track
{
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2); */
    border-radius: 10px;
    background-color: transparent;
}

.scrollbar-style::-webkit-scrollbar
{
    width: 8px;
    background-color: transparent;
}

.scrollbar-style::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
    background-color: $babyBlue-100;
}

.containerBox .scrollbar-popup-style::-webkit-scrollbar
{
background-color: transparent;
border-radius: 10px;
}


/* start Global */
p {
    margin: 0;
}
.text-myPrimary
{
    color: $primaryColor;
}
.text-myDarkPrimary
{
    color: $darkPrimary;
}
.text-mySecondary{
    color: $secondaryColor;
}
.btn-myPrimary
{
    border: none;
    padding: 8px 60px;
    background-color: $primaryColor;
    color: $baseColor;
    border-radius: $borderRadius;
}
.btn-transparent
{
    border: none;
    background-color: transparent;
    color: $secondaryColor;
    // margin: auto;
}
ul {
    padding: 0;
}
li {
    list-style: none;
}
.btn-myPrimary:hover {
    background-color: $secondaryColor;
}
.fs-12 {
    font-size: 12px;
}
.fs-14 {
    font-size: 14px;
}
.my-6
{
    margin: 6em 0;
}
.cursor-pointer
{
    cursor: pointer;
}
a {
    text-decoration: none;
}
.text-cairo
{
    font-family: 'Cairo', sans-serif;
}
.text-left {
    text-align: left;
}
/* end Global */
            /* start navbar */
.flag-img {
    cursor: pointer;
    border-radius: 40px;
    width: 36px;
    height: 36px;
    border: solid 2px $primaryColor;
}
.flag-img:hover {
    border-color: $secondaryColor;
}
.logo-width
{
    width: 300px;
}
/* end Navbar */

        /* start about */

.rotate-180
{
    transform: rotateY(180deg);
}
.typed
{
    min-height: 50px;
}
.about-item-2
{
    margin-top: -40px;
}

        /* end about */

        /* start form Request  */

:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}
/* .inputRequest-start
{
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
[dir=rtl] .inputRequest-start
{
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.inputRequest-end
{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
} */
/* [dir=rtl] .inputRequest-end
{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
} */
[dir="rtl"] {
    .inputRequest-start
    {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    .inputRequest-end
    {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    .form-request-demo .react-tel-input .form-control
    {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
}
[dir="ltr"] {
    .inputRequest-start
    {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    .inputRequest-end
    {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    .form-request-demo .react-tel-input .form-control
    {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
}
.phone-input input {
    width: 250px !important;
}
.form-request-demo input{
    width: 220px;
    border: 0;
    padding: 12px 20px;
    background-color: $babyBlue-50;
}
.btn-request
{
    border: none;
    padding: 12px 0;
    width: 190px ;
    background-color: $primaryColor;
    color: $baseColor;
}

/* .phone-input {
    width: 220px ;
    border: 0;
}  */
.form-request-demo .react-tel-input .form-control
{
    border: none !important;
    background: $babyBlue-50 !important;
    height: auto !important;
    /* width:  !important; */
    padding-left: 48px;
    padding-right: 10px;
}
.form-request-demo .react-tel-input .flag-dropdown
{
    border: none !important;
    background-color: $babyBlue-50 !important;
}
.form-request-demo .react-tel-input .flag-dropdown {
    border-radius: 20px 20px 20px 20px !important;
}
.form-request-demo .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus
{
    background-color: $babyBlue-50 !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.form-request-demo .react-tel-input .flag-dropdown.open .selected-flag
{
    background-color: $babyBlue-50 !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.form-control:focus {
    border-color: transparent !important;
    outline: 0;
    box-shadow: none !important;
}
.form-request-demo .react-tel-input .country-list
{
    width: 244px;
}
.form-request-demo .react-tel-input .country-list .search {
    top: -2px;
}
.phone-input ul .search input {
    width: 85% !important; 
}
            /* end form Request */

            /* start features */
.my-card-front
{
    background-color: $babyBlue-50;
    text-align: center;
    border-radius: $borderRadius;
}
.image-card {
    height: 170px;
}
.card-slide-1
{  
    background-color: $primaryTile;
}
.card-slide-2
{  
    background-color: $thirdTile;
}
.card-slide-3
{  
    background-color: $fourthColor;
}
.card-slide-4
{  
    background-color: $secondaryTile;
}
            /* end features */
            /* start why egarhub */
.paragraph-why {
    line-height: 2;
    color: $darkPrimary;
}
.arrow-rtl
{
    transform: rotate(180deg);
}
        /* end why egarhub */
        /* start request a demo */
.requestDemo {
    /* position: absolute;
    left: 0;
    right: 0; */
    background-color: $primaryColor;
    padding: 30px 0;
}
#requestDemo button {
    background-color: $secondaryColor;
}
        /* end request a demo */
        /* start footer */
.logo-footer {
    width: 60px;
}
        /* end footer  */
        /* start confirm sending */
.parent-thanks {
    margin-top: 40px;
}

            /* flip card */

.flip-card {
    /* background-color: #f1f1f1; */
    height: 355px;
    perspective: 1000px;
  }

  .flip-card-inner {
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s ease-in-out;
    transform-style: preserve-3d;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background-color: $babyBlue-50 !important;
    text-align: center;
    border-radius: $borderRadius;
  }
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    /* background-color: $primaryColor ; */
    color: $baseColor;
    border-radius: $borderRadius;
    padding: 0px 20px;
  }
  .flip-card-front {
    /* background-color: #bbb; */
    color: black;
  }

  .flip-card-back {
    /* background-color: #555; */
    color: white;
    transform: rotateY(180deg);
  }

            /* payment registration  */
.registration-image {
    /* background-color: $primaryColor); */
    height: 100%;
    /* position: relative; */
}
.image-item {
    /* position: absolute; */
    /* bottom: 0; */
}
.registration-input input{
    width: 100%;
    border: 0;
    padding: 12px 20px;
    background-color: $babyBlue-50;
    border-radius: 20px !important;
}
.registration-input-tel{
    width: 100%;
    border: 0;
    padding: 6px 16px;
    background-color: $babyBlue-50;
    border-radius: 20px ;
}
.registration-input-tel .react-tel-input .form-control
{
    border: none !important;
    background: $babyBlue-50 !important;
    height: auto !important;
    width:  100%;
    padding-left: 48px;
    padding-right: 10px;
}
.registration-input-tel .react-tel-input .flag-dropdown
{
    border: none !important;
    background-color: $babyBlue-50 !important;
}
.registration-input-tel .react-tel-input .flag-dropdown {
    border-radius: 20px 20px 20px 20px !important;
}
.registration-input-tel .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus
{
    background-color: $babyBlue-50 !important;
    border-radius: $borderRadius;
}
.registration-input-tel .react-tel-input .flag-dropdown.open .selected-flag
{
    background-color: $babyBlue-50 !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.registration-input-tel .react-tel-input .country-list
{
    width: 244px;
}
.registration-input-tel .react-tel-input .country-list .search {
    top: -2px;
}
.registration-input-mobile-wallet input {
    width: 100%;
    border: 0;
    padding: 12px 20px;
    background-color: $baseColor;
    border-radius: 20px !important;
}
.registration-btn {
    border: none;
    padding: 12px 0;
    width: 200px ;
    background-color: $primaryColor;
    color: $baseColor;
    border-radius: $borderRadius;
}
.registration-input-radio input{
    width: 15px;
}
.error-message {
    font-size: 14px;
}
.registration-input-domain {
    background-color: $babyBlue-50;
    border-radius: $borderRadius;
    padding: 0 20px 0 0;
}
.invoice {
    background-color: $babyBlue-50;
    /* height: 120px; */
    border-radius: $borderRadius;
}
.invoice-title {
    font-size: 16px;
    font-weight: 600;
}
.registration-input-units {
    font-size: 40px;
    font-weight: bold;
    color: $primaryColor;
    background-color: transparent;
    border: none;
    width: 80px;
}
.invoice-line {
    background-color: #00000025;
    height: 88px;
    width: 2px;
    
}
.egarhub-domain {
    color: $mediumGray;
}

            /*START SELECT-REACT */
.css-13cymwt-control
{
    background-color: $babyBlue-50 !important;
    border: none !important;
    border-radius: $borderRadius;
    width: 100%;
    padding: 5px 16px;
    font-size: 16px;
    margin-inline: auto;
    border-radius: 20px !important;
    box-shadow: none !important;   
}
.css-t3ipsp-control {
    background-color: $babyBlue-50 !important;
    border: none !important;
    border-radius: $borderRadius;
    width: 100%;
    padding: 5px 16px;
    font-size: 16px;
    margin-inline: auto;
    border-radius: 20px !important;
    box-shadow: none !important;
}
.css-t3ipsp-control:hover {
    border: none !important;
}
.css-7t1uod-control
{
    border-radius: 10px !important;
}
.css-2i78nt-control{
    border-radius: 10px !important;
}
.css-1xc3v61-indicatorContainer
{
    /* opacity: 0; */
}
.css-vnxml5-control
{
    outline: -webkit-focus-ring-color auto 0px;
    padding: 15px 14px 15px 40px;
    font-size: 16px;
    border-radius: 20px !important;
}
.css-tj5bde-Svg
{
    /* opacity: 0; */
}
.css-1u9des2-indicatorSeparator
{
    /* opacity: 0; */
}
.css-qbdosj-Input {
    color: $primaryColor !important;
}
.css-1wy0on6 {
    /* opacity: 0 !important; */
}
.parent-icon-search-navbar-old
{
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    border: 0;
}
.css-15lsz6c-indicatorContainer
{
    /* color: transparent !important; */
}
            /*END SELECT-REACT */

            /* Start Toggle Button */
.btn-container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.btn-color-mode-switch {
    display: inline-block;
    margin: 0px;
    position: relative;
}
[dir=ltr] .btn-color-mode-switch>label.btn-color-mode-switch-inner {
    margin: 0px;
    width: 200px;
    height: 50px;
    background-color: $babyBlue-100;
    border-radius: $borderRadius;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    /* box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset; */
    display: block;
}

[dir=ltr] .btn-color-mode-switch>label.btn-color-mode-switch-inner:before {
    content: attr(data-on);
    position: absolute;
    font-size: 18px;
    font-weight: 600;
    top: 12px;
    left: 20px;
    color: #222;
    cursor: pointer;
}

[dir=ltr] .btn-color-mode-switch>label.btn-color-mode-switch-inner:after {
    content: attr(data-off);
    width: 110px;
    height: 46px;
    background: $primaryColor;
    border-radius: $borderRadius;
    position: absolute;
    font-size: 18px;
    display: flex;
    justify-content: center;
    right: 2px;
    top: 2px;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 6px -2px #111;
    padding: 10px 0px;
    color: $baseColor;
    font-weight: 600;
    cursor: pointer;
}

[dir=ltr] .btn-color-mode-switch input[type="checkbox"] {
    cursor: pointer;
    width: 40px;
    height: 25px;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: 1;
    margin: 0px;
}

[dir=ltr] .btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner {
    background-color: $babyBlue-100;
}

[dir=ltr] .btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner:after {
    content: attr(data-on);
    right: 88px;
}

[dir=ltr] .btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner:before {
    content: attr(data-off);
    left: auto;
    right: 20px;
}

            /* DIRECTION RTL */

[dir=rtl] .btn-color-mode-switch>label.btn-color-mode-switch-inner {
    margin: 0px;
    width: 180px;
    height: 40px;
    background-color: $babyBlue-100;
    border-radius: $borderRadius;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    /* box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset; */
    display: block;
}

[dir=rtl] .btn-color-mode-switch>label.btn-color-mode-switch-inner:before {
    content: attr(data-on);
    position: absolute;
    font-size: 18px;
    font-weight: 600;
    top: 7px;
    left: 20px;
    color: #222;
    cursor: pointer;
}

[dir=rtl] .btn-color-mode-switch>label.btn-color-mode-switch-inner:after {
    content: attr(data-off);
    width: 90px;
    height: 36px;
    background: $primaryColor;
    border-radius: $borderRadius;
    position: absolute;
    font-size: 18px;
    display: flex;
    justify-content: center;
    right: 2px;
    top: 2px;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 6px -2px #111;
    padding: 5px 0px;
    color: $baseColor;
    font-weight: 600;
    cursor: pointer;
}

[dir=rtl] .btn-color-mode-switch input[type="checkbox"] {
    cursor: pointer;
    width: 40px;
    height: 25px;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: 1;
    margin: 0px;
}

[dir=rtl] .btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner {
    background-color: $babyBlue-100;
}

[dir=rtl] .btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner:after {
    content: attr(data-on);
    right: 88px;
}

[dir=rtl] .btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner:before {
    content: attr(data-off);
    left: auto;
    right: 20px;
}
              /* End Toggle Button */

              /* START PAYMENT GATEWAY */
.payment-gateway {
    background-color: $babyBlue-50;
    /* margin: 30px 0; */
    /* padding: 0 0 20px 0; */
    border-radius: $borderRadius;
}
#justify-tab-example-tab-card {
    border-top-left-radius: 20px;
    border-top-right-radius: 0px;
}
#justify-tab-example-tab-wallet {
    border-top-left-radius: 0;
    border-top-right-radius: 20px;
}
[dir=rtl] #justify-tab-example-tab-card {
    border-top-left-radius: 0;
    border-top-right-radius: 20px;
}
[dir=rtl] #justify-tab-example-tab-wallet {
    border-top-left-radius: 20px;
    border-top-right-radius: 0;
}
.at-icon {
    font-size: 16px;
    color: $darkPrimary;
}

 /* START Policy POPUP */

.containerBox
{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.415);
    height: 100vh;
    width: 100vw;
    inset: 0;
    z-index: 99999;
}
.lightContainer
{
    overflow-y: auto;
    padding: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* transform: tr; */
    background-color: $baseColor;
    border-radius: $borderRadius;
    width: 50%;
    /* height: fit-content; */
    height: 550px;
}
@media (max-width: 991px) {
    .lightContainer
    {
        width: 90%;
        height: 50vh;
    }
   
}
.pricing-plans {
    margin-bottom: 30px;
    padding: 0;
    .table-list {
        width: 100%;
        .responsive-table {
            .table-header {
                letter-spacing: 0.03em;
                display: flex;
                justify-content: space-between;
                margin-bottom: 0px;
                border-bottom: solid 1px hsla(0, 0%, 0%, 0.1);
                .table-header-card-go {
                    padding: 20px 30px 45px;
                    margin-top: 38px;
                    .button-card {
                        width: 100%;
                        font-size: 18px;
                        padding: 10px 0px;
                    }
                    @media (max-width : 991px) and (min-width : 767px) {
                        padding: 20px 10px 45px;
                        .button-card  {
                            font-size: 16px;
                        }
                    }
                }
                .section-details {
                    padding: 0;
                    padding: 10px 0px;
                }
                .table-header-card-pro {
                    padding: 0 0 30px;
                    border-top-left-radius: $borderRadius;
                    border-top-right-radius: $borderRadius;
                    background-color: $babyBlue-50;
                    .header-card-pro {
                        padding: 7px 0;
                        background-color: $primaryColor;
                        border-top-left-radius: $borderRadius;
                        border-top-right-radius: $borderRadius;
                        color: $baseColor;
                        font-weight: 600;
                        text-align: center;
                    }
                    .body-card-pro {
                        padding: 15px 30px;
                        .title-table-header {
                            color: $primaryColor;
                        }
                        .desc-table-header {
                            color: $lightGray;
                        }
                        .button-card {
                            width: 100%;
                            font-size: 18px;
                            padding: 10px 0px;
                        }
                        @media (max-width : 991px) and (min-width : 767px) {
                            padding: 20px 10px 45px;
                            .button-card  {
                                font-size: 16px;
                            }
                        }
                    }
                }
                .col-2 {
                    padding: 0;
                }
                .col-3 {
                    padding: 0;
                }
                .title-table-header {
                    font-weight: 900;
                    color: $primaryColor;
                }
                .desc-table-header {
                    font-size: 14px;
                    color: $lightGray;
                }
                @media (max-width : 767px){
                    display: inline-block ;
                    justify-content: space-between;
                    margin-bottom: 0px;
                    border-bottom: none;
                    .col {
                        width: 100%;
                    }
                    .table-header-card-go {
                        border: 2px solid #ebebec;
                        border-radius: $borderRadius;
                        margin-bottom: 25px;
                    }
                    .table-header-card-pro {
                        border-radius: $borderRadius;
                        margin-bottom: 25px;
                    }

                }
            }
            .table-row {
                display: flex;
                justify-content: space-between;
                margin-bottom: 0px;
                border-bottom: solid 1px hsla(0, 0%, 0%, 0.1);
                color: $darkGray;
                .col-2 {
                    text-align: center;                    
                }
                .col-3 {
                    text-align: center;
                    background-color: $babyBlue-50;
                }
            }
            .col-1 {
                flex-basis: 40%;
                align-self: center;
                padding: 15px 30px;
            }
            .col-2 {
                flex-basis: 30%;
                align-self: center;
                padding: 15px 30px;
            }
            .col-3 {
                flex-basis: 30%;
                align-self: center;
                padding: 15px 30px; 
            }
            .price-icon {
                color: $primaryTile;
                fill: none;
            }
            @media (max-width : 991px) and (min-width : 767px) {
                .col-1 {
                    flex-basis: 33%;
                }
                .col-2 {
                    flex-basis: 33%;
                }
                .col-3 {
                    flex-basis: 33%;
                    padding: 26px 30px; 
                }
            }
            @media (max-width : 767px) {
                .col-1 {
                    flex-basis: 60%;
                    padding: 15px 5px; 
                }
                .col-2 {
                    flex-basis: 40%;
                    padding: 15px 5px; 
                }
                .col-3 {
                    flex-basis: 40%;
                    padding: 26px 5px; 
                }
            }
        }
        .number-row {
            color: $babyBlue-50;
        }
        .settings-list {
        }
    }
}
