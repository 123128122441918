@import "./global.css";


@media ( 1201px  <= width <= 1600px) {
    
}
@media ( 993px  <= width <= 1200px) {
    .title-why
    {
        width: 65px ;
    }
}
@media ( 768px  <= width <= 992px) {
    .title-why
    {
        width: 65px ;
    }
    .invoice-title {
        font-size: 14px;
    }
}
@media ( 0px  <= width <= 500px) {
    .title-why
    {
        width: 65px ;
    }
    .btn-request
    {
        width: 144px;
    }
    .form-request-demo input {
        width: 144px;
    }
    .phone-input input {
        width: 144px !important;
    }
}



@media (min-width: 400px) and (max-width: 500px) {
    .btn-request
    {
        width: 134px;
    }
    .form-request-demo input {
        width: 184px !important;
    }
    .phone-input input {
        width: 224px !important;
    }
    .react-tel-input .country-list
    {
        width: 224px;
    }
}
@media (min-width: 360px) and (max-width: 400px) {
    .btn-request
    {
        width: 124px;
    }
    .form-request-demo input {
        width: 164px !important;
    }
    .phone-input input {
        width: 204px !important;
    }
    .react-tel-input .country-list
    {
        width: 204px;
    }
}
/* @media ( 575px  <= width <= 767px) {
    
}
@media (0px  <= width <= 575px) {

} */
